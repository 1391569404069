import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  public logs = {status: false, page: '/logs', title: 'Logs' }
  public logsList = []


  public categories = [
    { id: 1, name: 'Close Friend' },
    { id: 3, name: 'Friend' },
    { id: 4, name: 'Acquaintance' },
    { id: 5, name: 'Networking Connection' },
    { id: 6, name: 'Family Member' },
    { id: 7, name: 'Estranged' }
  ];

  constructor(
  ) {

 
   }

  timestampFormat(date: Date) {
    const dayy = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const monthh = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
    const datex = `${date.getFullYear()}-${monthh}-${dayy}`;
    return datex;
  }

  removeAccents(text) {
    text = text.toLowerCase();
    text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
    text = text.replace(new RegExp('[Ñ]', 'gi'), 'n');
    return text;
  }

  SaveLogs(title, error: any) {
    this.logsList.push({title: title, time: new Date(), message: error});
    console.log('Error in LogList', this.logsList);
    console.error('Error',error)
  }
}
