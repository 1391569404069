import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'incident',
    loadChildren: () => import('./pages/incident/incident.module').then( m => m.IncidentPageModule)
  },
  {
    path: 'firstaid',
    loadChildren: () => import('./pages/firstaid/firstaid.module').then( m => m.FirstaidPageModule)
  },
  {
    path: 'about-incident',
    loadChildren: () => import('./pages/about-incident/about-incident.module').then( m => m.AboutIncidentPageModule)
  },
  {
    path: 'sites',
    loadChildren: () => import('./pages/sites/sites.module').then( m => m.SitesPageModule)
  },
  {
    path: 'about-page',
    loadChildren: () => import('./pages/about-page/about-page.module').then( m => m.AboutPagePageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'team',
    loadChildren: () => import('./pages/team/team.module').then( m => m.TeamPageModule)
  },

];





@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
