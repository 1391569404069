import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { Incident } from '../models/incident';

@Injectable({
  providedIn: 'root',
})
export class DataserviceService {
  remoteUrl: string = 'https://api.consultare.app/api/';
  //remoteUrl: string = "http://192.168.1.7:8000/api/";
  remoteRoutes = [
    'categorias',
    'informacoes',
    'equipe',
    'destaques',
    //"incidentes",
    'incidentes/all',
    'icons'
  ];

  useLocalStorage: boolean = false;
  noResultsFound: boolean = false;
  iconsPackDone: boolean = false;
  getIconStarted: boolean = false;
  getIconFinished: boolean = false;
  destaquesList: Incident[] = [];
  categoriasList: any;
  equipesList: any;
  sitesList: any;
  todaList: any;
  iconsList: any;
  filteredIncident: any;
  finishedRoute: any;
  finished: boolean;
  finishedChecker: any = [];
  finishedBtn: boolean;
  constructor(private storage: Storage, public http: HttpClient) {
    this.onFirstUse();
  }

  async onFirstUse() {
    this.storage.get('consultare_launch_01').then(async (res) => {
      console.log('Resposta primeira vez', res);
      if (res === null) {
        //it's the 1st time ever he uses the app
        console.log('is 1st time I use the app');
        this.useLocalStorage = false;
        this.storage.set('consultare_launch_01', 'TRUE');
        this.storage.set('consultareOfflineIcons', 0);

        try {
          // await Promise.all([this.checkStorage()]);
          this.checkStorage().then(res=>{
            setTimeout(() => {
              this.useLocalStorage = true;
              window.location.reload()
            }, 100);
          })
          // await Promise.all

        } catch (error) {
          console.error('Erro ao fazer o download:', error);
        }
      } else {
        //if is NOT the first time, create storage
        console.log('I already have the app');
        this.checkStorage();
        this.useLocalStorage = true;
        this.getIcons();

        //1. check for updates comparing dates
        //2. update data if API date is newer
      }
    });
  }

  //check if localstorage exists
  async checkStorage(): Promise<void> {
    console.log(this.remoteRoutes);
    try {
      const requests = this.remoteRoutes.map(async (route) => {
        const key = 'consultare_app_' + route;
        const res = await this.storage.get(key);
        console.log(key, res)
        if (res === null) {
          // storage DOES NOT exist... Create it.
          await this.createStorage(key, route);
        } else {
          // storage exists... then compare last modified dates
          await this.compareLastDates(key, route);
        }
      });
      // Aguarde todas as operações serem concluídas
      await Promise.all(requests);
    } catch (error) {
      console.error('Erro ao verificar o armazenamento:', error);
    }
  }

    //check if localstorage exists
    async setStorage(): Promise<void> {
      console.log(this.remoteRoutes);
      try {
        const requests = this.remoteRoutes.map(async (route) => {
          const key = 'consultare_app_' + route;
          const res = await this.storage.get(key);
            // storage DOES NOT exist... Create it.
            await this.createStorage(key, route);
            // storage exists... then compare last modified dates
        });
        // Aguarde todas as operações serem concluídas
        await Promise.all(requests);
      } catch (error) {
        console.error('Erro ao verificar o armazenamento:', error);
      }
    }

  //check get data
  async checkApi(): Promise<void> {
    try {
      // await Promise.all([
        // async () => {
          for (let route of this.remoteRoutes) {
            return new Promise((resolve, reject) => {
            let key = 'consultare_app_' + route;
            this.getDataFromApi(key, route);
            resolve()
          })   
        }
        // },
      // ]);

    } catch (error) {}
  }

  //create local storage and get
  async createStorage(storageKey: string, apiRoute: string): Promise<void> {
    try {
      // storage for last date modification, and for data of this storage
      await this.storage.set(storageKey + 'date', new Date());
      await this.storage.set(storageKey, '');
      await this.setIcons();
      console.log('storage created for key: ', storageKey);
      await this.getDataFromApi(storageKey, apiRoute);
    } catch (error) {
      console.warn('Erro ao criar armazenamento para a chave:', storageKey, error);
    }
  }

  //compare local and cloud dates (to check the last date such routes was modified on Api)
  compareLastDates(storageKey, apiRoute) {
    console.log(
      'storage: ',
      storageKey,
      ' already exits. compare dates for updating data'
    );
    this.storage.get(storageKey + 'date').then((res) => {
      let apiDate = ''; //last date of this route
      if (apiDate > res) {
        console.log('api-date is newer. Change local data...');
        this.getDataFromApi(storageKey, apiRoute);
      } else {
        console.log(apiRoute, ' is up-to-date, then LocalData not changed!');
      }
    });
  }

  //get data from cloud and update storage local storage with new cloud-data
  getDataFromApi(key: string, route: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.get(this.remoteUrl + route).subscribe(
        (res) => {
          this.storage.set(key, res).then(
            () => {
              console.log('Dados atualizados para a rota', route);
            },
            (error) => {
              console.log('Erro ao atualizar dados para a chave', key, error);
              reject(error);
            }
          );
        },
        (error) => {
          console.log(
            'Erro ao obter dados da API, rota:',
            route,
            JSON.stringify(error)
          );
          reject(error);
        },
        () => {
          console.log('Processo concluído para a rota', route);
          this.finished = true;
          this.finishedRoute = route;

          this.finishedChecker.push(route);
          this.checkAllDownloadedData(route)
            .then(() => {
              resolve(); // Resolver a promessa aqui
            })
            .catch(reject);
        }
      );
    });
  }

  setIcons(): Promise<void> {
    console.log('começando a baixar ícones...');
    this.getIconStarted = true;
    return new Promise((resolve, reject) => {
      this.http.get(this.remoteUrl + 'icons').subscribe(
        (res) => {
          this.storage.set('consultare_icons', res).then(
            () => {
              console.log('Ícones salvos');
              this.storage.set('consultareOfflineIcons', 1);
            },
            (error) => {
              console.log('Erro ao salvar ícones', error);
              reject(error);
            }
          );
        },
        (error) => {
          console.log('Erro ao obter icons.json', error);
          reject(error);
        },
        () => {
          console.log('setIcons concluído');
          this.storage.set('consultareOfflineIcons', 1);
          this.iconsPackDone = true;
          this.getIconFinished = true;
          this.getIconStarted = false;
          resolve(); // Resolver a promessa aqui
        }
      );
    });
  }

  getIcons() {
    this.storage.get('consultareOfflineIcons').then((res) => {
      if (res == 1) {
        //use icons from Storage
        this.storage.get('consultare_icons').then((res) => {
          console.log('usando icones do Storage');
          this.iconsList = res;
        });
      } else {
        //use icons from API
        this.http.get(this.remoteUrl + 'icons').subscribe(
          (data) => {
            console.log('usando icones da API ', data);
            this.iconsList = data;
          },
          (error) => {
            console.warn('error de API');
          }
        );
      }
    });
  }

  checkAllDownloadedData(route): Promise<void> {
    console.log('download info checker... ', this.finishedChecker);
    return new Promise((resolve, reject) => {
      if (
        this.finishedChecker.includes('informacoes') &&
        this.finishedChecker.includes('equipe') &&
        this.finishedChecker.includes('categorias') &&
        this.finishedChecker.includes('destaques') &&
        this.finishedChecker.includes('incidentes/all')
      ) {
        console.log('all items pushed...');
        this.finishedBtn = true;
      }
      resolve();
    });
  }

  //get data on local to populate the app
  getLocalData(dataRoute: string, parameter?: number) {
    switch (dataRoute) {
      case 'forHome': {
        console.log('Procurando dados para home');
        this.getCategories();
        this.getFeatured();
        break;
      }
      case 'incidentes': {
        this.getIncidents();
        break;
      }
      case 'incidente': {
        this.getFirstAid(parameter);
        break;
      }
      case 'informacoes': {
        this.infoSites();
        break;
      }
      case 'equipe': {
        this.getTeam();
        break;
      }
    }
  }

  getCategories() {
    console.log('Procurando categorias');
    this.storage.get('consultare_app_categorias').then(
      (res) => {
        console.log('Categorias ', res);
        if (res === null) {
          this.useLocalStorage = false;
        } else {
          if (res.length > 0) {
            console.log('categorias has items ', res.length, res);
            this.categoriasList = res;
            this.noResultsFound = false;
          } else {
            console.log("categorias doesn't have info...");
            this.noResultsFound = true;
          }
          this.useLocalStorage = true;
        }
      },
      (error) => {
        console.log('error storage categorias ', error);
      }
    );
  }

  getFeatured() {
    console.log('Procurando destaques');
    this.storage.get('consultare_app_destaques').then(
      (res) => {
        console.log('Procurando por destaques ', res);
        if (res === null) {
          console.log('#featured ', res);
          this.useLocalStorage = false;
        } else {
          if (res.length > 0) {
            console.log('destaques has items ', res.length);
            this.destaquesList = res;
            this.noResultsFound = false;
          } else {
            console.log("destaques doesn't have info...");
            this.noResultsFound = true;
          }
          this.useLocalStorage = true;
        }
      },
      (error) => {
        console.log('error storage destaques ', error);
      }
    );
  }

  getTeam() {
    this.storage.get('consultare_app_equipe').then((res) => {
      if (res.length > 0) {
        console.log('equipe has items ', res.length);
        this.equipesList = res;
        this.noResultsFound = false;
      } else {
        console.log("equipe doesn't have info...");
        this.noResultsFound = true;
      }
    });
  }

  getIncidents(categoryId?: number) {
    if (categoryId) {
      this.storage.get('consultare_app_incidentes/all').then(
        (res) => {
          if (res === null) {
            this.useLocalStorage = false;
          } else {
            if (res.length > 0) {
              this.todaList = [];
              for (let item of res) {
                if (item.categoria_id == categoryId) {
                  this.todaList.push(item);
                  this.noResultsFound = false;
                }
              }
              console.log(
                'todalist/by_categ ',
                categoryId,
                ' has items ',
                this.todaList.length,
                this.todaList
              );
            } else {
              console.log("todalist/by_categ doesn't have info...");
              this.noResultsFound = true;
            }
            this.useLocalStorage = true;
          }
        },
        (error) => console.log('error storage incidents ', error)
      );
    } else {
      this.storage.get('consultare_app_incidentes/all').then((res) => {
        if (res.length > 0) {
          console.log('todalist has items ', res.length);
          this.todaList = [];
          this.todaList = res;
          this.noResultsFound = false;
        } else {
          console.log("todalist doesn't have info...");
          this.noResultsFound = true;
        }
      });
    }
  }

  getFirstAid(incidentId: number) {
    this.storage.get('consultare_app_incidentes/all').then(
      (res) => {
        if (res === null) {
          this.useLocalStorage = false;
        } else {
          if (res.length > 0) {
            for (let item of res) {
              if (item.id == incidentId) {
                this.filteredIncident = item;
                this.noResultsFound = false;
              }
            }
            console.log(
              'filtered-Incident ',
              incidentId,
              ' has result ',
              this.filteredIncident
            );
          } else {
            console.log('filtered-Incident ', incidentId, ' has NO result');
            this.noResultsFound = true;
          }
          this.useLocalStorage = true;
        }
      },
      (error) => console.log('error storage first-aid ', error)
    );
  }

  infoSites() {
    this.storage.get('consultare_app_informacoes').then((res) => {
      if (res.length > 0) {
        console.log('informacoes has items ', res.length);
        this.sitesList = res;
        this.noResultsFound = false;
      } else {
        console.log("informacoes doesn't have info...");
        this.noResultsFound = true;
      }
    });
  }
}
