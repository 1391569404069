import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MenuController, NavController, NavParams } from '@ionic/angular';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
@Component({
  selector: 'app-about-incident',
  templateUrl: './about-incident.page.html',
  styleUrls: ['./about-incident.page.scss'],
})
export class AboutIncidentPage implements OnInit {
  @ViewChild('searchField') inputElement: HTMLIonSearchbarElement;

  incidentId;
  incidentList;
  iconsList;

  searchQuery: string = '';
  results: any;
  auxList;
  mensagem;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public dataProvider: DataserviceService,
    private sanitize: DomSanitizer,
    // private appInsightsService: AppinsightsProvider,
    private storage: Storage,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private menu: MenuController,
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.incidentId = this.navParams.get('incident_id')
    this.dataProvider.getLocalData("incidentes");
    this.iconsList = this.dataProvider.iconsList;

    setTimeout(() => {
      this.incidentList = this.auxList = this.dataProvider.todaList
      this.shuffleIncidentList(this.dataProvider.todaList);
      console.log('lista-all', this.incidentList, 'icons-all', this.iconsList);

      if (this.iconsList == null) {
        let c = setInterval(() => {
          if (this.dataProvider.iconsList != null) {
            this.iconsList = this.dataProvider.iconsList;
            clearInterval(c);
          }
        }, 2000);
      }
    }, 500);

    if (this.navParams.get('search')) {
      setTimeout(() => {
        this.focusOnSearch();
      }, 600);
    }
  }

  focusOnSearch() {
    this.inputElement.setFocus();
  }

  sanitizeImage(image) {
    return this.sanitize.bypassSecurityTrustResourceUrl(image);
  }

  getImage(name) {
    if (this.iconsList != null) {
      for (let icon of this.iconsList) {
        if (icon.url == name) {
          return this.sanitizeImage(icon.image);
        }
      }
    }
  }

  choosenIncident(incident) {
    // this.navCtrl.push(FirstaidPage, {
    //   incident_id: incident.id,
    // });
    this.router.navigate(['/firstaid'], 
    {
      queryParams: {
         incident_id: incident.id,
       }
     });
  }

  shuffleIncidentList(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }


  searchForIncident(event, auxList) {
    let value = event.target.value

    if (value && value.trim() != '') {
      this.incidentList = this.auxList.filter((atual) => {
        let posicao = atual.incidente.toLowerCase().indexOf(value.toLowerCase());
        return (atual.incidente.toLowerCase().indexOf(value.toLowerCase()) > -1);
      });
    }
    else {
      this.incidentList = this.auxList
    }
    this.mensagem = (this.incidentList.length == 0) ? this.mensagem = ['Nenhum resultado encontrado'] : [];
  }

  viewCategories() {
    // this.navCtrl.setRoot(CategoryPage)
    this.router.navigate(['home'])
  }

  openMenu() {
    this.menu.open()
    // setTimeout(() => {
    //   this.menu.close();
    // }, 500);
  }

}
