import { Component, OnInit } from '@angular/core';
import { MenuController, NavController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.page.html',
  styleUrls: ['./about-page.page.scss'],
})
export class AboutPagePage implements OnInit {

  constructor(
    public navCtrl: NavController, 
    public navParams: NavParams,
    private menu: MenuController,
    // private appInsightsService: AppinsightsProvider,
    // private sharingProvider: SharingProvider,
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    console.log('ionViewDidLoad AboutPage');
  }

  rateApp(){
    window.open("https://play.google.com/store/apps/details?id=org.equipmoz.consultare", "_blank");
    //this.firebase.logEvent('appRate_clicked', {})
  }

  shareApp(){
    // this.sharingProvider.shareApplication();//TODO:: Por fazer
    //this.firebase.logEvent('appSharing_clicked', {})
  }

  openMenu() {
    this.menu.open()
    // setTimeout(() => {
    //   this.menu.close();
    // }, 500);
  }

}
