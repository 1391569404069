import { Component, OnInit } from '@angular/core';
import { MenuController, NavController, NavParams } from '@ionic/angular';
import { Sites } from 'src/app/models/incident';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'
@Component({
  selector: 'app-sites',
  templateUrl: './sites.page.html',
  styleUrls: ['./sites.page.scss'],
})
export class SitesPage implements OnInit {

  infoSitesList: Sites[]
  showRetryButton: boolean = true;
  constructor(
    public navCtrl: NavController, 
    public navParams: NavParams,
    public dataProvider: DataserviceService,
    private browser: InAppBrowser,
    private menu: MenuController,
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.dataProvider.getLocalData("informacoes");
    setTimeout(() => {
      console.log('sitesList', this.dataProvider.sitesList, this.showRetryButton)
      this.infoSitesList = this.dataProvider.sitesList;
    }, 1500);
  }

  linkToWebsite(site) {
    console.log("Website para acessar ", site)
    let browser = this.browser.create(
      site.url,
      'location=yes,' +
      'toolbar=yes,' +
      '' +
      'hardwareback=yes'
    );

    browser.show();
  }

  reloadApp() {
    this.showRetryButton = false;
    setTimeout(() => {
      // this.ionViewDidLoad();
    }, 2000);
  }

  retryDownload() {
    this.dataProvider.getDataFromApi('consultare_app_informacoes', 'informacoes');
    this.reloadApp();
  }

  openMenu() {
    this.menu.open()
    // setTimeout(() => {
    //   this.menu.close();
    // }, 500);
  }

}
