/* eslint-disable @typescript-eslint/dot-notation */
import { Time } from '@angular/common';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root',
})

export class StorageService {


  constructor(
    private storage: Storage,
    private helperService: HelperService,
    ) {
    this.initStorage();
  }

  async initStorage() {
    await this.storage.defineDriver(CordovaSQLiteDriver);
    const storage = await this.storage.create();
    this.storage = storage;
  }

  deleteStorageItem(value: string) {  // infer the value as a string
    console.log("test", value);
    this.storage.remove(value).then(() => {
      this.getAllFriendList();
    });
}

  //Salva todos os dados inseridos pelo Formulário e Importados.
  //para Criar e Editar
  async setFriendsData(friendKey: string, friendData: any) {
    await this.storage.set(friendKey, friendData).then((res) => {});
  }

  //carregar toda a lista de contactos guardados
  async getAllFriendList() {

    await this.storage.forEach((value, key) => {
      const castValue = value;
      if (key.includes('consultare_App_')) {
        console.log("consultare_app data: ", JSON.stringify(castValue));
        //Se não tiver os atributos 'family' e 'dates', inicializa-los como vazios []
        if (!castValue.hasOwnProperty('family')) {
          castValue.family = [];
        }
        if (!castValue.hasOwnProperty('dates')) {
          castValue.dates = [];
        }
      }
    });
  }


  async getConfig(key: string) {
    const data = await this.storage.get(key);
    console.log('get-configs', key, data);
    return data;
  }

  //para colocar a data to formato de Timestamp
  timestampFormat(date: Date) {
    const datex = date.toISOString().split('T')[0];
    const timex = date.toTimeString().split(' ')[0];
    return `${datex}`;
  }

}



