import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent, MenuController, ModalController, NavController, NavParams } from '@ionic/angular';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { ProfilePage } from '../profile/profile.page';
// import { Content } from 'ionic-angular';
@Component({
  selector: 'app-team',
  templateUrl: './team.page.html',
  styleUrls: ['./team.page.scss'],
})
export class TeamPage implements OnInit {

  @ViewChild(IonContent) content: IonContent;
  teamList: Object;
  preload: boolean = true;
  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public dataProvider: DataserviceService,
    public modalCtrl: ModalController,
    private menu: MenuController,
    // private appInsightsService: AppinsightsProvider
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.dataProvider.getLocalData("equipe");

    setTimeout(() => {
      console.log('team', this.dataProvider.equipesList)
      this.teamList = this.dataProvider.equipesList;
      this.preload = false;
    }, 500);
  }

  viewProfile(member) {
    //TODO:: Por fazer
    // const modal = this.modalCtrl.create(ProfilePage,
    //    { member_id: member.id }, 
    //    { showBackdrop: false });
    // modal.present()
  }

  scrollHandler(event) {
    if (event.scrollTop >= 150) {
      document.querySelector('.header').classList.remove('top');
    } else if (event.scrollTop < 150) {
      document.querySelector('.header').classList.add('top');
    }
  }

  reloadApp() {
    this.preload = true;
    setTimeout(() => {
      // this.ionViewDidLoad();
    }, 2000);
  }

  retryDownload() {
    this.dataProvider.getDataFromApi('consultare_app_equipe', 'equipe');
    this.reloadApp();
  }

  openMenu() {
    this.menu.open()
    // setTimeout(() => {
    //   this.menu.close();
    // }, 500);
  }


}
