import { Component } from '@angular/core';
import { Storage } from '@ionic/storage';
import { StatusBar, Style } from '@capacitor/status-bar';
import { MenuController, Platform } from '@ionic/angular';
import { SplashScreen, SplashScreenPlugin } from '@capacitor/splash-screen';
import { HelperService } from './services/helper.service';
import { StorageService } from './services/storage.service';
import { AboutIncidentPage } from './pages/about-incident/about-incident.page';
import { SitesPage } from './pages/sites/sites.page';
import { DataserviceService } from './services/dataservice.service';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { AboutPagePage } from './pages/about-page/about-page.page';
import { TeamPageModule } from './pages/team/team.module';
import { TeamPage } from './pages/team/team.page';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public contacts;
  public pages = [
    { page: '/home', title: 'Inicial' , icon:'home'},
    { title: 'Sobre o App',page:'/about-page', component: AboutPagePage, icon: 'document' },
    { title: 'Equipe',page:'team', component: TeamPage, icon: 'people' },
    { title: 'Lista de Incidentes',page:'about-incident', component: AboutIncidentPage, icon: 'medkit' },
    { title: 'Informações',page:'sites', component: SitesPage, icon: 'information-circle' }
    
  ];
  theStorage: Storage | null = null;

  constructor(
    private storageService: StorageService,
    private storage: Storage,
    private platform: Platform,
    private menu: MenuController,
    public helper: HelperService,
    // public splashScreen: SplashScreen,
    // public statusBar: StatusBar,
  ) {
    this.init();
    // if (true) {
    //   this.pages.push({ page: '/logs', title: 'Logs' },)
      
    // }

  }
  

  async init() {
    // status bar functionality android
    if (this.platform.is('capacitor')) {
      await StatusBar.setOverlaysWebView({ overlay: true });
      StatusBar.setStyle({ style: Style.Light });
      setTimeout(() => {
        SplashScreen.hide();
      }, 3000);
    }

    this.platform.ready().then(() => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.

      // Status bar configurations
      // this.statusBar.styleLightContent();
      // this.statusBar.overlaysWebView(true);
      // this.statusBar.show();

      // this.splashScreen.hide();
      // this.statusBar.styleDefault();
      // this.statusBar.backgroundColorByHexString('#32ace2');
      // this.statusBar.styleBlackTranslucent();
    }).catch();
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
  }

  async ngOnInit() {
    const storage = await this.storage.create();
    this.theStorage = await storage;

  }

  openMenu() {
    this.menu.open()
    // setTimeout(() => {
    //   this.menu.close();
    // }, 500);
  }
}
